    <template>
    <b-card-code
        title="Reset Password" 
    >
          <!-- form -->
          <validation-observer
            ref="ResetPasswordForm"
            #default="{invalid}"      
          >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="resetpassword"
          >
               <b-form-group
                  label="Password"
                  label-for="Password">
                    
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required">
                        <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid':null"
                        >
                            <b-form-input
                            id="password"
                            v-model="password"
                            :state="errors.length > 0 ? false:null"
                            :type="passwordFieldType"
                            name="password"
                            placeholder="············"
                            />
                            <b-input-group-append is-text>
                                <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                />
                            </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="invalid"
                >
                  Save
                </b-button>
            </b-form>
          </validation-observer>

  </b-card-code>
</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BForm, BButton,BFormTextarea, BInputGroupAppend,BInputGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol, 
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox
  },
  data() {
    return {
      id:this.$store.state.app.user.id,
      password:"",

    }
  },

  methods: {

    resetpassword: function() {
        this.$refs.ResetPasswordForm.validate().then(success => {
        if (success) {
          //call to axios
            let data = {
                password:this.password,
            }
            const options = {
              method: 'PATCH',
              headers: { 'content-type': 'application/json' },
              data:data,
              url: process.env.VUE_APP_BASEURL+'user/users/'+this.id+'/',

          }
          var self = this
          this.$http(options).then( ( res ) => {
            console.log(res)
            if(res.data.id){
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Password changed! ",
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    })
            }
          })
        }  
        })
      }
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  }
}
</script>